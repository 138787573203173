<template>
    <div>
        <v-data-table
                :headers="headers"
                :items="promotions"
                :options.sync="pagination"
                :server-items-length="totalPromotions"
                :loading="loading"
                :footer-props="{
        'showFirstLastPage':true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
                class="elevation-1"
        >
            <template v-slot:item.image="{ item }">
                <v-img
                        max-height="150"
                        max-width="150"
                        contain
                        :src="item.thumbnailPath"
                ></v-img>
            </template>

            <template v-slot:item.allCities="{ item }">
                <v-switch v-model="item.allCities" disabled></v-switch>
            </template>

            <template v-slot:item.cityName="{ item }">
                {{item.city != null ? item.city.name : ""}}
            </template>
            <template v-slot:item.start="{ item }">
                {{ parseDate(item.start) }}
            </template>
            <template v-slot:item.end="{ item }">
                {{ parseDate(item.end) }}
            </template>
            <template v-slot:item.title="{ item }">
                {{ item.titleTypeDefinition.title }}
            </template>
            <template v-slot:item.active="{ item }">
                <v-switch
                        v-model="item.active"
                        @click="changePromotionStatus(item.id, item.active)"
                ></v-switch>
            </template>
            <template v-slot:item.clear="{ item }">
                <v-avatar v-if="currentUser.superAdmin || (!currentUser.superAdmin && !item.allCities)" color="teal" size="26" rounded>
                    <router-link
                            :to="{
              name: 'PromotionEdit',
              params: { promotionId: item.id }
            }"
                    >
                        <v-icon dark>mdi-pencil</v-icon>
                    </router-link>
                </v-avatar>
            </template>
            <template v-slot:item.add="{ item }">
                <v-avatar v-if="currentUser.superAdmin || (!currentUser.superAdmin && !item.allCities)" color="red darken-4" size="26" rounded>
                    <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
                </v-avatar>
            </template>
            <template v-slot:body.prepend>
                <tr>
                    <td>
                        <v-spacer></v-spacer>
                    </td>
                    <td>
                        <v-spacer></v-spacer>
                    </td>
                    <td>
                        <v-spacer></v-spacer>
                    </td>
                    <td>
                        <v-spacer></v-spacer>
                    </td>
                    <td>
                        <v-spacer></v-spacer>
                    </td>
                    <td>
                        <v-spacer></v-spacer>
                    </td>
                    <td>
                        <v-spacer></v-spacer>
                    </td>
                    <td>
                        <v-spacer></v-spacer>
                    </td>
                    <td>
                        <v-spacer></v-spacer>
                    </td>
                    <td>
                        <v-spacer></v-spacer>
                    </td>
                    <td>
                        <v-avatar color="indigo" size="30">
                            <v-icon
                                    dark
                                    @click="
                  page = 1;
                  retrievePromotions();
                "
                            >mdi-magnify
                            </v-icon>
                        </v-avatar>
                    </td>
                    <td>
                        <v-avatar color="grey" size="30">
                            <v-icon
                                    dark
                                    @click="
                  page = 1;
                  clearSearch();
                "
                            >mdi-close
                            </v-icon>
                        </v-avatar>
                    </td>
                    <td>
                        <router-link :to="{ name: 'PromotionCreate' }">
                            <v-avatar color="teal" size="26" rounded>
                                <v-icon dark>mdi-plus</v-icon>
                            </v-avatar>
                        </router-link>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <ConfirmDialog ref="confirm"/>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import moment from "moment";
    import ConfirmDialog from "@/view/pages/ConfirmDialog";
    import {mapGetters} from "vuex";

    export default {
        name : "promotions" ,
        computed : {
            ...mapGetters(["currentUser"]),
            headers() {
                return [
                    { text : "Id" , align : "start" , sortable : false , value : "id" } ,
                    { text : this.$i18n.t('common.title') , value : "title" , sortable : false } ,
                    { text : this.$i18n.t('common.description') , value : "description" , sortable : false } ,
                    { text : this.$i18n.t('common.start') , value : "start" , sortable : false } ,
                    { text : this.$i18n.t('common.end') , value : "end" , sortable : false } ,
                    { text : this.$i18n.t('common.image') , value : "image" , sortable : false } ,
                    { text : this.$i18n.t('common.type') , value : "type" , sortable : false } ,
                    { text : this.$i18n.t('commonTable.city') , value : "cityName" , sortable : false } ,
                    { text : this.$i18n.t('pages.recommendedCategories.allCities') , value : "allCities" , sortable : false } ,
                    { text : this.$i18n.t('pages.sectors.active') , value : "active" , sortable : false } ,
                    { text : "" , value : "search" , width : "2%" , sortable : false } ,
                    { text : "" , value : "clear" , width : "2%" , sortable : false } ,
                    { text : "" , value : "add" , width : "2%" , sortable : false }
                ]
            }
        } ,
        data() {
            return {
                promotionTitle : "" ,
                totalPromotions : 0 ,
                promotions : [] ,
                loading : true ,
                pagination : {} ,
                showEditPromotionDialog : false ,
                shopList : [] ,
                selectedShops : [] ,
                dialogDelete : false ,
                itemToDelete : null ,
                errors : []
            };
        } ,
        watch : {
            pagination : {
                handler() {
                    this.retrievePromotions ();
                } ,
                deep : true
            }
        } ,
        mounted() {
            this.$store.dispatch ( SET_BREADCRUMB , [{ title : "Promotions" }] );
        } ,
        components : {
            ConfirmDialog
        } ,
        methods : {
            changePromotionStatus(id , active) {
                this.loading = true;
                ApiService.patch ( `api/promotion/${id}` , {
                    active : active
                } )
                    .then ( response => {
                        this.$log.debug ( "active: " , response.data.active );
                        this.retrievePromotions ();
                    } )
                    .catch ( error => {
                        this.$log.error ( "Error: " , error );
                        this.errored = true;
                    } )
                    .finally ( () => (this.loading = false) );
            } ,
            parseDate(date) {
                return moment
                    .utc ( date )
                    .local ()
                    .format ( "yyyy-MM-DD HH:mm:ss" );
            } ,
            async delRecord(item) {
                if (
                    await this.$refs.confirm.open (
                        this.$i18n.t('common.confirm') ,
                        this.$i18n.t('pages.promotions.deleteMessage') +
                        item.id +
                        " ?"
                    )
                ) {
                    this.deleteRecord ( item );
                }
            } ,
            deleteRecord(item) {
                this.deletePromotion ( item.id );
            } ,
            updateSelectedCities(selectedShops) {
                this.selectedShops = selectedShops;
            } ,
            deletePromotion(promotionId) {
                this.loading = true;
                ApiService.delete ( `api/promotion/${promotionId}` )
                    .then ( response => {
                        this.$log.debug ( "Promotion deleted: " , response );
                        this.retrievePromotions ();
                    } )
                    .catch ( error => {
                        this.$log.error ( "Error: " , error );
                        this.errored = true;
                    } )
                    .finally ( () => (this.loading = false) );
            } ,
            getRequestParams() {
                let params = {};

                let sort;
                let sortBy = this.pagination.sortBy;
                let sortDesc = this.pagination.sortDesc;
                if (sortBy.length === 1 && sortDesc.length === 1) {
                    sort = sortBy[0];
                    if (sortDesc[0] === true) sort += ",desc";
                }
                params["sort"] = sort;
                params["page"] = this.pagination.page;
                params["size"] = this.pagination.itemsPerPage;

                return params;
            } ,
            clearSearch() {
                this.promotionTitle = "";
            } ,
            retrievePromotions() {
                const params = this.getRequestParams ();
                return new Promise ( resolve => {
                    this.loading = true;
                    ApiService.query ( "api/promotion" , {
                        params
                    } ).then ( response => {
                        // JSON responses are automatically parsed.
                        this.$log.debug ( "Promotions: " , response.data.content );
                        this.promotions = response.data.content;
                        this.totalPromotions = response.data.totalElements;
                        this.totalPages = response.data.totalPages;
                        this.loading = false;
                        let title = this.totalPromotions != null ? this.totalPromotions : 0;
                        this.$store.dispatch ( SET_BREADCRUMB , [
                            { title : "Promotions: " + title }
                        ] );
                        resolve ();
                    } );
                } );
            }
        }
    };
</script>

<style>
    .v-data-table.v-data-table.v-data-table >>> td {
        font-size: 16px;
    }

    .v-icon.v-icon {
        font-size: 24px !important;
    }

    tbody tr:nth-of-type(even) {
        background-color: rgba(236, 237, 237);
    }

    tbody tr:nth-of-type(odd) {
        background-color: rgb(250, 250, 250);
    }
</style>
